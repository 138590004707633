import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class TransactionsService {
	protected readonly EndPoint =
		environment.API_ENDPOINT + 'payments/transactions';

	constructor(protected readonly http: HttpClient) {}

	filter(
		option: {
			take?: any;
			page?: any;
			searchTerm?: any;
			orderCode?: any;
			paymentType?: any;
			transactionId?: any;
			transactionTime?: any;
			transactionType?: any;
		} = {}
	) {
		return this.http.get(
			`${this.EndPoint}?take=${option.take || ''}&page=${
				option.page || ''
			}&searchTerm=${option.searchTerm || ''}&orderCode=${
				option.orderCode || ''
			}&paymentType=${option.paymentType || ''}&transactionId=${
				option.transactionId || ''
			}&transactionTime=${option.transactionTime || ''}&transactionType=${
				option.transactionType || ''
			}`
		);
	}
}
