import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class OrderStatusMessageService extends BaseService {
	protected readonly EndPoint =
		environment.API_ENDPOINT + 'orderStatusMessages/';

	constructor(protected readonly http: HttpClient) {
		super(http);
	}

	filter(
		option: {
			take?: any;
			page?: any;
			searchTerm?: any;
			isActive?: any;
			status?: any;
		} = {}
	): Observable<any> {
		return this.http.get(
			`${this.EndPoint}filter?take=${option.take || ''}&page=${
				option.page || ''
			}&searchTerm=${option.searchTerm || ''}&status=${
				option.status || ''
			}&isActive=${
				option.isActive == true || option.isActive == false
					? option.isActive
					: ''
			}`
		);
	}

	deleteMany(ids: string[]): Observable<any> {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: { ids },
		};
		return this.http.delete(this.EndPoint + 'bulkDelete', options);
	}
}
