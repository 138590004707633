import { CurrentUser } from './../../@shared/model/currentUser.model';

export interface CurrentUserState {
	currentUser: CurrentUser;
	jwtToken: string;
}

export const CurrentUserInitialState: CurrentUserState = {
	currentUser: {},
	jwtToken: '',
};
