import { first, mergeMap } from 'rxjs/operators';
import { AppState } from './../../@state/app.state';
import { getCurrentUserAndTokenSelector } from './../../@state/current-user/current-user.selector';
import { CurrentUserAndToken } from './../model/currentUserAndToken.model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpEvent,
} from '@angular/common/http';
import { select, Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root',
})
export class AuthorizationInterceptor implements HttpInterceptor {
	constructor(private store: Store<AppState>) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return this.addToken(req).pipe(
			first(),
			mergeMap((requestWithToken: HttpRequest<any>) =>
				next.handle(requestWithToken)
			)
		);
	}

	private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
		// NOTE: DO NOT try to immediately setup this selector in the constructor or as an assignment in a
		// class member variable as there's no stores available when this interceptor fires fires up and
		// as a result it'll throw a runtime error.
		return this.store.pipe(
			select(getCurrentUserAndTokenSelector),
			first(),
			mergeMap((data: CurrentUserAndToken) => {
				if (data && data.jwtToken) {
					const token = data.jwtToken;
					request = request.clone({
						setHeaders: {
							Authorization: `Bearer ${token}`,
							'CREATED-BY':
								`${data.currentUser.firstName} ${data.currentUser.lastName}`.trim(),
						},
					});
				} else {
					console.warn(`Invalid token!!! Cannot use token`);
				}
				return of(request);
			})
		);
	}
}
