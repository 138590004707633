import { OrderStatusMessageService } from './../../../@shared/services/orderStatusMessage.service';
import { OrderStatusMessage } from './../../../@shared/model/orderStatusMessage.model copy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import {
	FormGroup,
	FormControl,
	FormBuilder,
	Validators,
} from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@UntilDestroy()
@Component({
	selector: 'app-shop-order-dialog',
	templateUrl: './shop-order-dialog.component.html',
	styleUrls: ['./shop-order-dialog.component.scss'],
})
export class ShopOrderDialogComponent implements OnInit {
	pageTitle: string;
	form: FormGroup;

	custom = new FormControl('');

	orderId: string;

	orderStatusMessage: OrderStatusMessage[];

	constructor(
		public dialogRef: MatDialogRef<ShopOrderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private orderStatusMessageService: OrderStatusMessageService
	) {}

	ngOnInit(): void {
		this.pageTitle = this.data.pageTitle;
		this.orderId = this.data.orderId;
		this.enableForm();
		this.getOrderStatusMessage();
	}

	enableForm() {
		if (this.pageTitle === 'partialPay') {
		} else {
			this.form = this.fb.group({
				status: [this.data.pageTitle, [Validators.required]],
				order: [this.orderId, [Validators.required]],
				comments: ['', [Validators.required]],
			});
		}
	}

	getOrderStatusMessage() {
		this.orderStatusMessageService
			.filter({
				isActive: true,
				status: this.pageTitle,
			})
			.pipe(untilDestroyed(this))
			.subscribe((orderStatusMessage) => {
				this.orderStatusMessage = orderStatusMessage.data;
			});
	}

	save() {
		const data = { ...this.form.value };
		if (this.custom.value) {
			data.comments = this.custom.value;
		}
		this.dialogRef.close({ data });
	}
}
