import { ShopOrderDetailsComponent } from './../@module/shops/shop-order-details/shop-order-details.component';
import { AuthGuard } from './../@shared/guards/auth.guard';
import { AuthAccessDeniedGuard } from './../@shared/guards/auth-access-denied.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from '../@shared/components/layout/content/content.component';
import { content } from '../@shared/routes/routes';

const routes: Routes = [
	{
		path: '',
		loadChildren: () =>
			import('../@auth/auth.module').then((m) => m.AuthModule),
		canActivate: [AuthAccessDeniedGuard],
	},
	{
		path: 'vendor',
		component: ContentComponent,
		children: content,
		canActivate: [AuthGuard],
	},
	{
		path: 'vendor/shops/shop-details/:shopId/shop-order-details/:orderId',
		component: ShopOrderDetailsComponent,
	},
	{
		path: '**',
		redirectTo: '',
	},
];

@NgModule({
	imports: [
		[
			RouterModule.forRoot(routes, {
				anchorScrolling: 'enabled',
				scrollPositionRestoration: 'enabled',
				relativeLinkResolution: 'legacy',
			}),
		],
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
