import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrentUserState } from './current-user.state';

export const CURRENT_USER_STATE_NAME = 'currentUser';

const createCurrentUserState = createFeatureSelector<CurrentUserState>(
	CURRENT_USER_STATE_NAME
);

export const getCurrentUserSelector = createSelector(
	createCurrentUserState,
	(state: CurrentUserState) => {
		return state.currentUser;
	}
);

export const getCurrentTokenSelector = createSelector(
	createCurrentUserState,
	(state: CurrentUserState) => {
		return state.jwtToken;
	}
);

export const getCurrentUserAndTokenSelector = createSelector(
	createCurrentUserState,
	(state: CurrentUserState) => {
		return state;
	}
);
