import { environment } from './../../../environments/environment';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class OrderService extends BaseService {
	protected readonly EndPoint = environment.API_ENDPOINT + 'orders/';

	constructor(protected readonly http: HttpClient) {
		super(http);
	}

	filter(
		option: {
			take?: any;
			page?: any;
			searchTerm?: any;
		} = {}
	) {
		return this.http.get(
			`${this.EndPoint}?take=${option.take || ''}&page=${
				option.page || ''
			}&searchTerm=${option.searchTerm || ''}`
		);
	}

	get(
		option: {
			take?: any;
			page?: any;
			shop?: string;
			adminApproval?: string;
		} = {}
	) {
		return this.http.get(
			`${this.EndPoint}?take=${option.take || ''}&page=${
				option.page || ''
			}&shop=${option.shop || ''}&adminApproval=${option.adminApproval || ''}`
		);
	}
}
