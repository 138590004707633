import { TransactionsService } from './../../../@shared/services/transaction.service';
import { ShopService } from './../../../@shared/services/shop.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as JsBarcode from 'jsbarcode';

@UntilDestroy()
@Component({
	selector: 'app-shop-order-invoice',
	templateUrl: './shop-order-invoice.component.html',
	styleUrls: ['./shop-order-invoice.component.scss'],
})
export class ShopOrderInvoiceComponent implements OnInit {
	transactionHistory: any[] = [];
	currentShopVendor: any;

	constructor(
		public dialogRef: MatDialogRef<ShopOrderInvoiceComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private readonly transactionsService: TransactionsService,
		private readonly shopService: ShopService
	) {}

	ngOnInit() {
		this.genetateBarCode();

		this.transactionsService
			.filter({ orderCode: this.data.order.orderCode })
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.transactionHistory = res.data;
			});

		this.shopService
			.getById(this.data.order.shop.id)
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.currentShopVendor = res.data;
				console.log(this.currentShopVendor);
			});
	}

	genetateBarCode(): void {
		const orderCode = this.data.order.orderCode;
		JsBarcode('#barcode', orderCode, {
			height: 50,
			displayValue: false,
		});
	}
}
