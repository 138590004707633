import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from './custom-serializer';
import { RouterReducerState } from '@ngrx/router-store';

export const getRouterState = createFeatureSelector<
	RouterReducerState<RouterStateUrl>
>('router');

export const getCurrentRoute = createSelector(getRouterState, (router) => {
	return router.state;
});

export const getCurrentRouteId = createSelector(getRouterState, (router) => {
	return router.state.params.id;
});

export const getCurrentOrderId = createSelector(getRouterState, (router) => {
	return router.state.params.orderId;
});
