<div style="height: 100px;width: 300px;">
  <h1 class="text-center">Are you sure ???</h1>
  <div style="margin-top: 30px; display:flex; justify-content: center;">
    <button mat-button color="primary" class="mr-2" (click)='yesBtn()'>
      Yes
    </button>
    <button mat-button (click)='noBtn()'>
      No
    </button>
  </div>
</div>