import { OrderState } from './order.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const ORDER_STATE_NAME = 'Order';

export const getOrderState = createFeatureSelector<OrderState>(
	ORDER_STATE_NAME
);

export const getAllOrder = createSelector(
	getOrderState,
	(state: OrderState) => {
		return state;
	}
);
