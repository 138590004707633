import { CurrentUserState } from './../../@state/current-user/current-user.state';
import { ChangePassword } from './../model/changePassoword.model';
import { CurrentUser } from './../model/currentUser.model';
import { logout } from './../../@state/current-user/current-user.action';
import { untilDestroyed } from '@ngneat/until-destroy';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
	getCurrentUserSelector,
	getCurrentUserAndTokenSelector,
} from './../../@state/current-user/current-user.selector';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppState } from 'src/app/@state/app.state';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private readonly EndPoint = environment.API_ENDPOINT + 'auth/';

	constructor(
		private readonly http: HttpClient,
		private store: Store<AppState>
	) {}

	login(payload: any): Observable<any> {
		return this.http.post(`${this.EndPoint}login/vendor`, payload);
	}

	signup(payload: any): Observable<any> {
		return this.http.post(`${this.EndPoint}register/vendor`, payload);
	}

	logout() {
		this.store.dispatch(logout());
	}

	isLoggedIn() {
		let flag;
		this.store
			.select(getCurrentUserAndTokenSelector)
			.pipe(untilDestroyed(this))
			.subscribe((data: CurrentUserState) => {
				if (data) {
					if (data.currentUser && data.jwtToken) {
						flag = true;
					} else {
						flag = false;
					}
				} else {
					flag = false;
				}
			});
		return flag;
	}

	changePassword(payload: ChangePassword): Observable<any> {
		return this.http.post(`${this.EndPoint}changePassword/vendor`, payload);
	}

	generateNewJwt(payload) {
		return this.http.post(`${this.EndPoint}utils/refreshToken`, payload);
	}

	forgetPassword(payload: { phoneNumber: string }): Observable<any> {
		return this.http.post(`${this.EndPoint}forgetPassword/vendor`, payload);
	}

	verifyOtpAndResetPassword(payload: {
		phoneNumber: string;
		password: string;
		otp: string;
	}): Observable<any> {
		return this.http.post(`${this.EndPoint}resetPassword/vendor`, payload);
	}
}
