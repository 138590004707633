import { OrderNoteService } from './../../../@shared/services/orderNote.service';
import { OrderPaymentStatusComponent } from './../order-payment-status/order-payment-status.component';
import { NotificationService } from 'src/app/@shared/services/notification.service';
import { ShopOrderInvoiceComponent } from './../shop-order-invoice/shop-order-invoice.component';
import { ShopOrderDialogComponent } from './../shop-order-dialog/shop-order-dialog.component';
import { getAllOrder } from './../../../@state/order/order.selector';
import { OrderStatusService } from './../../../@shared/services/order-status.service';
import { OrderStatus } from './../../../@shared/model/order-status.model';
import { getCurrentOrderId } from './../../../@state/router/router.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerService } from './../../../@shared/services/customer.service';
import { OrderService } from './../../../@shared/services/order.service';
import { AppState } from './../../../@state/app.state';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from './../../../@shared/model/customer.model';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@UntilDestroy()
@Component({
	selector: 'app-shop-order-details',
	templateUrl: './shop-order-details.component.html',
	styleUrls: ['./shop-order-details.component.scss'],
})
export class ShopOrderDetailsComponent implements OnInit {
	currentOrderId: string;
	orderData: any = {};
	currentCustomerData: Customer;

	orderStatus: OrderStatus[] = [];

	noteForm: FormGroup;

	constructor(
		public dialog: MatDialog,
		private store: Store<AppState>,
		private orderService: OrderService,
		private orderStatusService: OrderStatusService,
		private customarService: CustomerService,
		private orderNoteService: OrderNoteService,
		private location: Location,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.setNoteForm();

		this.getCurrentOrderId();
		this.getOrderData(this.currentOrderId);

		if (!this.orderData) {
			this.orderService
				.getById(this.currentOrderId)
				.pipe(untilDestroyed(this))
				.subscribe((res: any) => {
					this.orderData = res.data;
					this.getOrderStatus(this.currentOrderId);
					this.getCustomerBlance();
				});
		}
	}

	setNoteForm() {
		this.noteForm = this.fb.group({
			note: [''],
		});
	}

	getCustomerBlance() {
		this.customarService
			.getById(this.orderData.customer.id)
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.currentCustomerData = res.data;
			});
	}

	getCurrentOrderId() {
		this.store
			.select(getCurrentOrderId)
			.pipe(untilDestroyed(this))
			.subscribe((id) => {
				this.currentOrderId = id;
			});
	}

	getOrderData(id: string) {
		this.store
			.select(getAllOrder)
			.pipe(untilDestroyed(this))
			.subscribe((state) => {
				let orderData = state.orders.find((data) => data.id === id);
				this.orderData = orderData;
				if (orderData) {
					this.getOrderStatus(this.currentOrderId);
					this.getCustomerBlance();
				}
			});
	}

	openOrderDialog(pageTitle: string) {
		const dialogRef = this.dialog.open(ShopOrderDialogComponent, {
			width: '500px',
			data: {
				pageTitle,
				orderId: this.currentOrderId,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.addOrderStatus(result.data);
			}
		});
	}

	updateOrderData() {
		this.orderService
			.getById(this.currentOrderId)
			.pipe(untilDestroyed(this))
			.subscribe((data: any) => {
				this.orderData = data.data;
			});
	}

	getOrderStatus(orderId: string) {
		this.orderStatusService
			.filter({ order: orderId })
			.pipe(untilDestroyed(this))
			.subscribe((orderStatus) => {
				this.orderStatus = orderStatus.data;
			});
	}

	addOrderStatus(data) {
		this.orderStatusService
			.create(data)
			.pipe(untilDestroyed(this))
			.subscribe((orderStatus) => {
				this.orderStatus = [orderStatus.data, ...this.orderStatus];
				this.updateOrderData();
			});
	}

	openInvoice() {
		this.dialog
			.open(ShopOrderInvoiceComponent, {
				data: {
					order: this.orderData,
				},
			})
			.afterClosed()
			.pipe(untilDestroyed(this))
			.subscribe((data: any) => {
				if (data) {
				}
			});
	}

	sendNote() {
		const payload = { ...this.noteForm.value, order: this.currentOrderId };
		this.orderNoteService
			.create(payload)
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.orderData.note.push(res.data);
				this.noteForm.reset();
			});
	}

	goBack() {
		this.location.back();
	}
}
