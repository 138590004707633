import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

  ngOnInit() {
    this.dialogRef
      .backdropClick()
      .subscribe(() => this.dialogRef.close({ success: false }));
  }

  yesBtn() {
    this.dialogRef.close({ success: true });
  }

  noBtn() {
    this.dialogRef.close({ success: false });
  }
}
