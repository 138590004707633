<form [formGroup]="form" style="width:800px">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 *ngIf="isCreateMode()" class="headline m-0" fxFlex="auto">New </h2>
    <!-- <h2 *ngIf="isUpdateMode()" class="headline m-0" fxFlex="auto">Update {{pageTitle}}</h2> -->
  </div>

  <mat-divider></mat-divider>

  <mat-dialog-content>
    <ng-container>
      <mat-form-field appearance="outline" class="full_width mt-4">
        <mat-label>Shop</mat-label>
        <input formControlName="shop" matInput>
      </mat-form-field>

      <mat-label>Payment Method</mat-label>
      <section class="checkbox_section">
        <mat-checkbox class="checkbox_margin" value="Bkash">Bkash</mat-checkbox>
        <mat-checkbox class="checkbox_margin" value="Nogad">Nogad</mat-checkbox>
        <mat-checkbox class="checkbox_margin" value="SEBL">SEBL</mat-checkbox>
        <mat-checkbox class="checkbox_margin" value="SSLCOMMERZ">SSLCOMMERZ</mat-checkbox>
        <mat-checkbox class="checkbox_margin" value="Balance">Balance</mat-checkbox>
        <mat-checkbox class="checkbox_margin" value="Bank">Bank</mat-checkbox>
        <mat-checkbox class="checkbox_margin" value="GiftCard">Gift card</mat-checkbox>
      </section>

      <mat-form-field appearance="outline" class="full_width">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput>
        <mat-error *ngIf="form.get('email').hasError('required')"><strong>Email</strong> is Required</mat-error>
        <mat-error *ngIf="form.get('email').hasError('pattern')"><strong>Email</strong> is Invalid</mat-error>
      </mat-form-field>

      <div class="full_width">
        <mat-form-field appearance="outline" class="half_width">
          <mat-label>Start Date</mat-label>
          <input formControlName="startDate" type="date" matInput>
        </mat-form-field>

        <mat-form-field appearance="outline" class="half_width">
          <mat-label>End Date</mat-label>
          <input formControlName="endDate" type="date" matInput>
        </mat-form-field>
      </div>

      <div class="full_width">
        <mat-form-field appearance="outline" class="half_width">
          <mat-label>Payment Status</mat-label>
          <mat-select formControlName="paymentStatus" multiple>
            <mat-option value="all">All</mat-option>
            <mat-option value="paid">Paid</mat-option>
            <mat-option value="partial">Partial</mat-option>
            <mat-option value="unpaid">Unpaid</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="half_width">
          <mat-label>Order Status</mat-label>
          <mat-select formControlName="orderStatus" multiple>
            <mat-option *ngFor="let status of allOrderStatus" [value]="status">{{status}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCEL</button>
    <button *ngIf="isCreateMode()" (click)="save()" color="primary" mat-button type="submit"
      [disabled]="form.invalid">SEND</button>
  </mat-dialog-actions>
</form>