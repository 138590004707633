import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class ShopService extends BaseService {
	protected readonly EndPoint = environment.API_ENDPOINT + 'shops/';

	constructor(protected readonly http: HttpClient) {
		super(http);
	}

	filter(
		option: {
			take?: any;
			page?: any;
			searchTerm?: any;
			vendor?: any;
		} = {}
	): Observable<any> {
		return this.http.get(
			`${this.EndPoint}filter?take=${option.take || ''}&page=${
				option.page || ''
			}&searchTerm=${option.searchTerm || ''}&vendor=${option.vendor || ''}`
		);
	}
}
