import { Order } from './../../@shared/model/order.model';
import { ProductImage } from './../../@shared/model/productImage.model';
import { Shop } from './../../@shared/model/shop.model';
import { createAction, props } from '@ngrx/store';
import { Product } from 'src/app/@shared/model/product.model';

export const loadShop = createAction(
	'[Shop Page] Load Shop',
	props<{
		options?: {
			pageSize?: number;
			pageIndex?: number;
			searchTerm?: string;
			vendor?: string;
		};
	}>()
);

export const loadShopSuccess = createAction(
	'[Shop Page] Load Shop Success',
	props<{ shop: Shop[]; total: number }>()
);

export const createShop = createAction(
	'[Shop Page] Create Shop',
	props<{ shop: Shop; total: number }>()
);

export const createShopSuccess = createAction(
	'[Shop Page] Create Shop Success',
	props<{ shop: Shop; total: number }>()
);

export const createShopProduct = createAction(
	'[Shop page] Create Shop Product',
	props<{ product: Product; total: number }>()
);

export const createShopProductSuccess = createAction(
	'[Shop page] Create Shop Product Success',
	props<{ product: Product; total: number }>()
);

export const createShopProductImage = createAction(
	'[Shop Page ] Create Product Image',
	props<{
		productId: string;
		productImage: ProductImage[];
	}>()
);

export const createShopProductImageSuccess = createAction(
	'[Shop Page ] Create Product Image Success',
	props<{
		productId: string;
		productImage: ProductImage[];
	}>()
);
export const updateShop = createAction(
	'[Shop Page] Update Shop',
	props<{ shop: Shop }>()
);

export const updateShopSuccess = createAction(
	'[Shop Page] Update Shop Success',
	props<{ shop: Shop }>()
);

export const updateShopProduct = createAction(
	'[Shop page] Shop Product Update',
	props<{ product: Product }>()
);

export const updateShopProductSuccess = createAction(
	'[Shop page] Shop Product Update Success',
	props<{ product: Product }>()
);

export const deleteShop = createAction(
	'[Shop Page] Delete Shop',
	props<{ id: string; total: number }>()
);

export const deleteShopSuccess = createAction(
	'[Shop Page] Delete Shop Success',
	props<{ id: string; total: number }>()
);

export const deleteOldProductImage = createAction(
	'[Shop Page] Delete Shop Product Image',
	props<{ productImageId: string }>()
);

export const deleteShopProduct = createAction(
	'[Shop Page] Delete Shop Product',
	props<{ id: string; total: number; shopId: string }>()
);

export const deleteShopProductSuccess = createAction(
	'[Shop Page] Delete Shop Product Success',
	props<{ id: string; total: number; shopId: string }>()
);

export const deleteOldProductImageSuccess = createAction(
	'[Shop Page] Delete Shop Product Image Success'
);

export const loadShopProduct = createAction(
	'[Shop Page] Shop Product Load',
	props<{
		options?: {
			pageSize?: number;
			pageIndex?: number;
			searchTerm?: string;
			shop?: string;
			brand?: string;
			category?: string;
			department?: string;
			subCategory?: string;
			isActive?: any;
		};
	}>()
);

export const loadShopProductSuccess = createAction(
	'[Shop Page] Shop Product Load Success',
	props<{ shopId: string; shopProduct: Product[]; total: number }>()
);

export const loadShopOrder = createAction(
	'[Shop page] Load Shop Order',
	props<{
		options?: {
			pageIndex?: number;
			pageSize?: number;
			shop?: string;
			adminApproval?: string;
		};
	}>()
);

export const loadShopOrderSuccess = createAction(
	'[Order page] Load Shop Order Success',
	props<{ order: Order[]; total: number }>()
);
