import { BaseService } from "./base.service";
import { environment } from "./../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CustomerService extends BaseService {
  protected readonly EndPoint = environment.API_ENDPOINT + "customers/";

  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  filter(
    option: {
      take?: any;
      page?: any;
      searchTerm?: any;
    } = {}
  ): Observable<any> {
    return this.http.get(
      `${this.EndPoint}filter?take=${option.take || ""}&page=${
        option.page || ""
      }&searchTerm=${option.searchTerm || ""}`
    );
  }
}
