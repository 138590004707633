import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getCurrentUserSelector } from 'src/app/@state/current-user/current-user.selector';
import { AppState } from '../../@state/app.state';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
} from '@angular/router';
import { NotificationService } from '../services/notification.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ProfileGuard implements CanActivate {
	constructor(
		private router: Router,
		private store: Store<AppState>,
		private readonly notificationService: NotificationService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.isProfileComplete()) {
			return true;
		} else {
			this.router.navigate(['vendor/profile']);
			this.notificationService.showPopupInfo('Please Update you Profile First');
			return false;
		}
	}

	isProfileComplete() {
		let flag: boolean;
		this.store
			.select(getCurrentUserSelector)
			.pipe(untilDestroyed(this))
			.subscribe((currentUser) => {
				if (
					currentUser &&
					currentUser.firstName &&
					currentUser.lastName &&
					currentUser.gender &&
					currentUser.email &&
					currentUser.dob
				) {
					flag = true;
				} else {
					flag = false;
				}
			});
		return flag;
	}
}
