import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BaseService {
  protected readonly EndPoint = environment.API_ENDPOINT;

  constructor(protected readonly http: HttpClient) {}

  getById(id: string) {
    return this.http.get(this.EndPoint + id);
  }

  create(payload: any): Observable<any> {
    return this.http.post(this.EndPoint, payload);
  }

  update(id: string, payload: any): Observable<any> {
    return this.http.put(this.EndPoint + id, payload);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this.EndPoint + id);
  }
}
