<form [formGroup]="multiImg" style="width:800px">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto"> Upload Product Image
    </h2>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <ng-container>
      <br>
      <h4>New Image: </h4>
      <button class="upload_button" mat-mini-fab color="primary" (click)="file.click()">
        <mat-icon>add</mat-icon>
      </button>
      <input type='file' style="visibility: hidden;" formControlName="productImages" accept=".png, .jpg, .jpeg" #file
        multiple (change)="imageCompileMultiple(file)" />

      <h4 *ngIf="imgArray?.length<=0" style="text-align: center; margin: -10px 0px 10px 0px;">No New Image
        Selected</h4>
      <div class="all_img">
        <ng-container *ngIf="imgArray.length>0">
          <div *ngFor="let img of imgArray; let i = index;" class="image_div">
            <img class="new_img" [src]="img?.imgUrl">
            <button mat-mini-fab color="warn" (click)="deleteNewImage(i)" class="delete_new"><span
                class="material-icons">
                delete
              </span></button>
          </div>
        </ng-container>
      </div>
      <br>
      <ng-container *ngIf="this.data.product.productImages.length>0">
        <h4>Old Image: </h4>
        <br>
        <div class="all_img">
          <div *ngFor="let pImg of this.data.product.productImages; let i= index;" class="image_div">
            <img class="old_img" [src]="pImg.link">
            <button mat-mini-fab color="warn" class="delete" (click)="oldImageDelete(pImg.id , i)"><span
                class="material-icons">
                delete
              </span></button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCEL</button>
    <button (click)="save()" color="primary" mat-button type="submit"
      [disabled]="multiImg.invalid || multiImg.pristine">UPLOAD</button>
  </mat-dialog-actions>
</form>