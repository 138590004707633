import { ProfileGuard } from './../guards/profile.guard';
import { Routes } from '@angular/router';

export const content: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	// {
	// 	path: 'orders',
	// 	loadChildren: () =>
	// 		import('../../@module/order/order.module').then((m) => m.OrderModule),
	// 	canActivate: [ProfileGuard],
	// },
	{
		path: 'dashboard',
		loadChildren: () =>
			import('../../@module/dashboard/dashboard.module').then(
				(m) => m.DashboardModule
			),
		canActivate: [ProfileGuard],
	},
	{
		path: 'shops',
		loadChildren: () =>
			import('../../@module/shops/shops.module').then((m) => m.ShopsModule),
		canActivate: [ProfileGuard],
	},
	// {
	// 	path: 'brands',
	// 	loadChildren: () =>
	// 		import('../../@module/brands/brands.module').then((m) => m.BrandsModule),
	// },
	// {
	// 	path: 'categories',
	// 	loadChildren: () =>
	// 		import('../../@module/categories/categories.module').then(
	// 			(m) => m.CategoriesModule
	// 		),
	// },
	// {
	// 	path: 'sub-category',
	// 	loadChildren: () =>
	// 		import('../../@module/sub-category/sub-category.module').then(
	// 			(m) => m.SubCategoryModule
	// 		),
	// },
	{
		path: 'products',
		loadChildren: () =>
			import('../../@module/products/products.module').then(
				(m) => m.ProductsModule
			),
		canActivate: [ProfileGuard],
	},
	{
		path: 'profile',
		loadChildren: () =>
			import('../../@module/profile/profile.module').then(
				(m) => m.ProfileModule
			),
	},
	// {
	// 	path: 'banner',
	// 	loadChildren: () =>
	// 		import('../../@module/banner/banner.module').then((m) => m.BannerModule),
	// },
	// {
	// 	path: 'analytics',
	// 	loadChildren: () =>
	// 		import('../../@module/analytics/analytics.module').then(
	// 			(m) => m.AnalyticsModule
	// 		),
	// },
	// {
	// 	path: 'balance',
	// 	loadChildren: () =>
	// 		import('../../@module/balance/balance.module').then(
	// 			(m) => m.BalanceModule
	// 		),
	// },
	// {
	// 	path: 'campaign',
	// 	loadChildren: () =>
	// 		import('../../@module/campaign/campaign.module').then(
	// 			(m) => m.CampaignModule
	// 		),
	// },
	// {
	// 	path: 'customer',
	// 	loadChildren: () =>
	// 		import('../../@module/customer/customer.module').then(
	// 			(m) => m.CustomerModule
	// 		),
	// },
	// {
	// 	path: 'footer',
	// 	loadChildren: () =>
	// 		import('../../@module/footer/footer.module').then((m) => m.FooterModule),
	// },
	// {
	// 	path: 'gift-card',
	// 	loadChildren: () =>
	// 		import('../../@module/gift-card/gift-card.module').then(
	// 			(m) => m.GiftCardModule
	// 		),
	// },
	// {
	// 	path: 'grocery-order',
	// 	loadChildren: () =>
	// 		import('../../@module/grocery-order/grocery-order.module').then(
	// 			(m) => m.GroceryOrderModule
	// 		),
	// },
	// {
	// 	path: 'grocery-shop',
	// 	loadChildren: () =>
	// 		import('../../@module/grocery-shop/grocery-shop.module').then(
	// 			(m) => m.GroceryShopModule
	// 		),
	// },
	// {
	// 	path: 'marketing',
	// 	loadChildren: () =>
	// 		import('../../@module/marketing/marketing.module').then(
	// 			(m) => m.MarketingModule
	// 		),
	// },
	// {
	// 	path: 'page-create',
	// 	loadChildren: () =>
	// 		import('../../@module/page-create/page-create.module').then(
	// 			(m) => m.PageCreateModule
	// 		),
	// },
	// {
	// 	path: 'payment-system',
	// 	loadChildren: () =>
	// 		import('../../@module/payment-system/payment-system.module').then(
	// 			(m) => m.PaymentSystemModule
	// 		),
	// },
	// {
	// 	path: 'refund',
	// 	loadChildren: () =>
	// 		import('../../@module/refund/refund.module').then((m) => m.RefundModule),
	// },
	// {
	// 	path: 'seller-bill',
	// 	loadChildren: () =>
	// 		import('../../@module/seller-bill/seller-bill.module').then(
	// 			(m) => m.SellerBillModule
	// 		),
	// },
	// {
	// 	path: 'settings',
	// 	loadChildren: () =>
	// 		import('../../@module/settings/settings.module').then(
	// 			(m) => m.SettingsModule
	// 		),
	// },
	// {
	// 	path: 'shipping-method',
	// 	loadChildren: () =>
	// 		import('../../@module/shipping-method/shipping-method.module').then(
	// 			(m) => m.ShippingMethodModule
	// 		),
	// },
	// {
	// 	path: 'user',
	// 	loadChildren: () =>
	// 		import('../../@module/user/user.module').then((m) => m.UserModule),
	// },
	// {
	// 	path: 'user-permission',
	// 	loadChildren: () =>
	// 		import('../../@module/user-permission/user-permission.module').then(
	// 			(m) => m.UserPermissionModule
	// 		),
	// },
	// {
	// 	path: 'departments',
	// 	loadChildren: () =>
	// 		import('../../@module/department/department.module').then(
	// 			(m) => m.DepartmentModule
	// 		),
	// },
	// {
	// 	path: 'vendors',
	// 	loadChildren: () =>
	// 		import('../../@module/vendor/vendor.module').then((m) => m.VendorModule),
	// },
	// {
	// 	path: 'discount',
	// 	loadChildren: () =>
	// 		import('../../@module/discount/discount.module').then(
	// 			(m) => m.DiscountModule
	// 		),
	// },
	// {
	// 	path: 'countries',
	// 	loadChildren: () =>
	// 		import('../../@module/country/country.module').then(
	// 			(m) => m.CountryModule
	// 		),
	// },
];
