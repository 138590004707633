import { AuthorizationInterceptor } from './../@shared/interceptor/authorization.interceptor';
import { RefreshTokenInterceptor } from './../@shared/interceptor/refreshToken.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

export const httpInterceptorProvider = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: RefreshTokenInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AuthorizationInterceptor,
		multi: true,
	},
];
