import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
  selector: "app-order-export-excel",
  templateUrl: "./order-export-excel.component.html",
  styleUrls: ["./order-export-excel.component.scss"],
})
export class OrderExportExcelComponent implements OnInit {
  form: FormGroup;

  allOrderStatus: any[] = [
    "All",
    "Pending",
    "Confirmed",
    "Processing",
    "Picker",
    "Shipped",
    "Delivered",
    "Cancel",
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<OrderExportExcelComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    if (this.data.mode === "create") {
      this.enableForm();
    }
  }

  save(): void {
    this.dialogRef.close(this.form.value);
  }

  enableForm() {
    this.form = this.fb.group({
      shop: [""],
      paymentMethod: [""],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^([a-zA-Z0-9]+)@([a-zA-z0-9]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
          ),
        ],
      ],
      startDate: [""],
      endDate: [""],
      paymentStatus: [[]],
      orderStatus: [[]],
    });

    if (this.data.shop) {
      this.form.get("shop").patchValue(this.data.shop);
      this.form.get("shop").disable();
    }
  }

  isCreateMode(): boolean {
    return this.data.mode.toLowerCase() === "create";
  }
}
