import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
  selector: "app-campaign-export-excel",
  templateUrl: "./campaign-export-excel.component.html",
  styleUrls: ["./campaign-export-excel.component.scss"],
})
export class CampaignExportExcelComponent implements OnInit {
  form: FormGroup;

  // allOrderStatus: any[] = ["All", "Pending", "Confirmed", "Processing", "Picker", "Shipped", "Delivered", "Cancel"]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CampaignExportExcelComponent>,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    if (this.data.mode === "create") {
      this.enableForm();
    }
  }

  save(): void {
    this.dialogRef.close(this.form.value);
  }

  enableForm() {
    this.form = this.fb.group({
      shop: [""],
      code: [""],
      coupon: [""],
      // paymentMethod: [''],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^([a-zA-Z0-9]+)@([a-zA-z0-9]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
          ),
        ],
      ],
      // startDate: [''],
      expireDate: [""],
      isActive: [],
      // paymentStatus: [[]],
      // orderStatus: [[]]
    });

    if (this.data.shop) {
      this.form.get("shop").patchValue(this.data.shop);
      this.form.get("shop").disable();
    }
  }

  isCreateMode(): boolean {
    return this.data.mode.toLowerCase() === "create";
  }
}
