import { CurrentUserAndToken } from './../../@shared/model/currentUserAndToken.model';
import { of } from 'rxjs';
import { NotificationService } from './../../@shared/services/notification.service';
import { AuthService } from './../../@shared/services/auth.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
	loadCurrentUser,
	loadCurrentUserSuccess,
	changePassword,
	changePasswordSuccess,
	changePasswordFail,
} from './current-user.action';
import { Router } from '@angular/router';

@Injectable()
export class CurrentUserEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly authService: AuthService,
		private readonly notificationService: NotificationService,
		private readonly router: Router
	) {}

	getCurrentUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(loadCurrentUser),
			map((currentUser: CurrentUserAndToken) => {
				return loadCurrentUserSuccess({
					currentUser: currentUser.currentUser,
					jwtToken: currentUser.jwtToken,
				});
			})
		);
	});

	changePassword$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(changePassword),
			mergeMap((action) => {
				return this.authService.changePassword(action.payload).pipe(
					map(() => {
						this.notificationService.showPopupSuccess(
							'Password Update Successful.'
						);

						this.authService.logout();
						this.router.navigate(['/login']);
						return changePasswordSuccess();
					}),
					catchError((error) => {
						this.notificationService.showPopupDanger(error.error.message);
						return of(changePasswordFail());
					})
				);
			})
		);
	});
}
