import { ImageSnippet } from './../../model/imageSnipped';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { AppState } from 'src/app/@state/app.state';
import { deleteOldProductImage } from 'src/app/@state/shop/shop.action';

@UntilDestroy()
@Component({
	selector: 'app-multiple-image-upload',
	templateUrl: './multiple-image-upload.component.html',
	styleUrls: ['./multiple-image-upload.component.scss'],
})
export class MultipleImageUploadComponent implements OnInit {
	multiImg: FormGroup;
	imgArray: any[] = [];
	isDelete: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<MultipleImageUploadComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private readonly store: Store<AppState>
	) {
		this.setForm();
	}

	ngOnInit() {}

	setForm() {
		this.multiImg = this.fb.group({
			productImages: [],
		});
	}

	save() {
		this.dialogRef.close({ imgArray: this.imgArray, isDelete: this.isDelete });
	}

	imageCompileMultiple(imageFile: any) {
		for (let i = 0; i < imageFile.files.length; i++) {
			const file: File = imageFile.files[i];
			const reader = new FileReader();
			reader.addEventListener('load', (event: any) => {
				let selectedFile = new ImageSnippet(event.target.result, file);
				let imgPush: { imgFile: any; imgUrl: string | ArrayBuffer } = {
					imgFile: selectedFile.file,
					imgUrl: reader.result,
				};
				this.imgArray.push(imgPush);
			});
			reader.readAsDataURL(file);
		}
		this.multiImg.reset();
		this.multiImg.markAsDirty();
	}

	deleteNewImage(index) {
		let localArray = this.imgArray;
		localArray.splice(index, 1);
		this.imgArray = localArray;
		if (this.imgArray.length <= 0) {
			this.multiImg.markAsPristine();
		}
	}

	oldImageDelete(id: string, index: any) {
		this.store.dispatch(deleteOldProductImage({ productImageId: id }));
		let localArray = this.data.product.productImages;
		localArray.splice(index, 1);
		this.data.product.productImages = localArray;
		this.isDelete = true;
	}
}
